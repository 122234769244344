@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@500&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&family=Source+Code+Pro:wght@600&display=swap');

.card {
  box-shadow:
    rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.alert {
  background-color: #ffb400;
  color: white;
  padding: 0.75rem 1.25rem !important;
  margin-bottom: 1rem !important;
  border: none !important;
  border-radius: 0 !important;
}

body {
  background-color: #eafce8 !important;
  margin: 0;
  font-family: 'Source Sans Pro', monospace !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slash-send {
  font-family: 'Source Code Pro', monospace;
}

a {
  text-decoration: none !important;
  color: black;
}

a:hover {
  color: rgb(111, 168, 93) !important;
}
