.slack-sign-in:hover {
  color: black !important;
}

.learn-ssend {
  background-color: #f90473 !important;
  border: none;
}

.learn-ssend:hover {
  color: rgb(255, 218, 250) !important;
}
